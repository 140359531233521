/**
 * List Schema of BlockchainVersion
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldComponent, FieldSet} from '@simpli/meta-schema'
import * as Component from '@simpli/vue-render-schema'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

/* TODO: review generated schema */
export class ListBlockchainVersionSchema extends DefaultSchema {
  readonly name = 'ListBlockchainVersion'

  readonly fieldSet: FieldSet<BlockchainVersion> = {
    idBlockchainVersionPk: (): FieldComponent => ({
      is: Component.Render,
    }),
    title: (): FieldComponent => ({
      is: Component.Render,
    }),
    color: (): FieldComponent => ({
      is: Component.Render,
    }),
    networkIdentifier: (): FieldComponent => ({
      is: Component.Render,
    }),
    tagName: (): FieldComponent => ({
      is: Component.Render,
    }),
  }
}
