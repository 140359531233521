
























































































































import {Component, Prop, Watch, Mixins} from 'vue-property-decorator'
import {MixinRouteMatch} from '@/components/mixins/MixinRouteMatch'
import FilterToggle from '@/components/FilterToggle.vue'
import FilterBlockchainVersion from '@/components/filters/FilterBlockchainVersion.vue'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'
import {BlockchainVersionCollection} from '@/model/collection/BlockchainVersionCollection'
import {ListBlockchainVersionSchema} from '@/schema/resource/BlockchainVersion/ListBlockchainVersionSchema'
import {ExportBlockchainVersionSchema} from '@/schema/resource/BlockchainVersion/ExportBlockchainVersionSchema'

@Component({
  components: {FilterToggle, FilterBlockchainVersion},
})
export default class ListBlockchainVersionView extends Mixins(MixinRouteMatch) {
  schema = new ListBlockchainVersionSchema()
  collection = new BlockchainVersionCollection()
  filterOpen = false

  async created() {
    if (this.hasQueryParams) {
      this.updateObjectFromRoute(this.collection)
    }
    await this.$await.run('hardQuery', () => this.collection.queryAsPage())
  }

  @Watch('collection', {deep: true})
  collectionEvent() {
    this.updateRouteFromObject(this.collection)
  }

  goToPersistView(item: BlockchainVersion) {
    this.$nav.pushByName('editBlockchainVersion', item.$id)
  }

  async removeItem(item: BlockchainVersion) {
    await this.$dialog.remove(item)
    await item.removeBlockchainVersion()
    await this.collection.queryAsPage()
  }

  async downloadXlsx() {
    const {ascending, orderBy, page, limit, ...params} = this.collection.params

    const coll = new BlockchainVersionCollection()
      .clearFilters()
      .addFilter(params)

    await coll.listExportBlockchainVersion()
    this.$xlsx.downloadFromSchema(
      coll.items,
      new ExportBlockchainVersionSchema()
    )
  }
}
