/**
 * Export Schema of BlockchainVersion
 * @author Simpli CLI generator
 */
import {$} from '@/facade'
import {DefaultSchema} from '@/schema/DefaultSchema'
import {FieldSet} from '@simpli/meta-schema'
import {BlockchainVersion} from '@/model/resource/BlockchainVersion'

/* TODO: review generated schema */
export class ExportBlockchainVersionSchema extends DefaultSchema {
  readonly name = 'ExportBlockchainVersion'

  readonly fieldSet: FieldSet<BlockchainVersion> = {
    idBlockchainVersionPk: schema => schema.model.idBlockchainVersionPk,
    title: schema => schema.model.title,
    color: schema => schema.model.color,
    identifier: schema => schema.model.networkIdentifier,
    active: schema => $.filter.bool(schema.model.active),
  }
}
